import AxiosCustomer from "@/config/customer-service";

function checkValueBetweenArray(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    const keys1 = Object.keys(obj1);

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false; // Different property value, not the same
      }
    }
  }

  return true;
}

function checkValeBetweenObject(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false; // Different property value, not the same
    }
  }
  return true;
}

const state = {
  bannerSilde: [],
  bannerAds: {},
  bannerPopup: [],
};
const mutations = {
  SET_BANNER_ADS(state, payload) {
    state.bannerAds = payload;
  },

  SET_BANNER_SLIDE(state, payload) {
    state.bannerSilde = payload.sort((a, b) => {
      return a.order - b.order;
    });
  },
};
const actions = {
  async getBannerAds({ commit, state }, type) {
    try {
      const { data } = await AxiosCustomer.get("site/getPageAds", {
        params: {
          page_name: type,
        },
      });
      if (data.status == "success") {
        const dataAds = data.data;
        if (checkValeBetweenObject(dataAds, state.bannerAds)) return;
        commit("SET_BANNER_ADS", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getBannerSlide({ commit, state }) {
    try {
      const { data } = await AxiosCustomer.get("site/getBannerSlide");
      if (data.status == "success") {
        const dataSlide = data.data;
        if (checkValueBetweenArray(dataSlide, state.bannerSilde)) return;
        commit("SET_BANNER_SLIDE", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
const getters = {
  getBannerAds: (state) => {
    return state.bannerAds;
  },
  getBannerSlide: (state) => {
    return state.bannerSilde;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueSnip from "vue-snip";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// import Vuebar from "vuebar";
import "./plugins/base";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueThailandAddress from "vue-thailand-address";
import "vue-thailand-address/dist/vue-thailand-address.css";
// import VJsf from '@koumoul/vjsf/lib/VJsf.js'
// import '@koumoul/vjsf/dist/main.css'
// import VueQuillEditor from '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueQrcodeReader from "vue-qrcode-reader";
import i18n from './plugins/i18n.js'
import VueSocialSharing from 'vue-social-sharing'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';

Vue.use(VueSocialSharing);
Vue.use(VueSnip);
Vue.use(VuePlyr);
Vue.use(VueQrcodeReader);
// Vue.component('VJsf', VJsf)
Vue.use(VueThailandAddress);

Vue.use(VueSweetalert2);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
// Vue.use(Vuebar);

// Vue.use(VueQuillEditor);
new Vue({
  vuetify,
  store,
  router,
  i18n,

  mounted() {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount("#app");

<template>
  <!-- <v-app -->
  <!--   id="materialpro" -->
  <!--   :class="`${ -->
  <!--     !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar' -->
  <!--   }`" -->
  <!-- > -->
  <v-app>
    <router-view v-show="!overlayLoading" />
    <div class="text-center">
      <v-overlay :z-index="zIndex" :value="overlayLoading" opacity="0.2">
        <v-progress-circular indeterminate size="64" :color="site.color"></v-progress-circular>
        <h4 class="mt-8" :style="{ color: site.color }">Login Line Loading ...</h4>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "App",
  data: () => ({
    zIndex: 205,
  }),
  computed: {
    ...mapState("defaultSystem", ["site"]),


    overlayLoading: {
      set() {
        this.$store.commit("defaultSystem/SET_LOADING", false);
      },
      get() {
        return this.$store.getters["defaultSystem/getLoading"];
      },
    },
  },
  watch: {
    overlayLoading: {
      handler() {
        this.overlayLoading;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.main {
  max-height: 2000px;
}

.menu-text {
  /* position: absolute; */
  z-index: 30;
}

.hamberger-menu {
  z-index: 40;
}

.display-coin {
  position: absolute;
  z-index: 50;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 100px;
}


@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: "Poppins", "Prompt" !important;
}
</style>

<style>
.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  color: rgba(0, 0, 0, .6) !important;
}
.sd-root-modern *{
  font-family: 'Prompt' !important;

}
</style>
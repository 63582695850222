import store from "@/store";
import AxiosBackend from "@/config/backend-service";
import i18n from "../plugins/i18n";

async function getSiteTemplate() {
  return new Promise((resolve) => {
    AxiosBackend.get("site/getSiteTemplate", {
      params: {
        url: " https://" + window.location.hostname,
      },
    })
      .then(({ data }) => {
        if (data.status == "success") {
          store.commit("defaultSystem/SET_SITE", data.data);
          i18n.locale = JSON.parse(
            localStorage.getItem("vuex")
          ).defaultSystem.site.language;
          resolve(data.data.name);
        }
      })
      .catch((err) => {
        throw err;
      });
  });
}
async function setCategory() {
  AxiosBackend.get("site/listMissionCategory").then(({ data }) => {
    if (data.status == "success") {
      store.commit("mission/SET_CATEGORY", data.data);
    }
  });
  }

export const checkLineUser = async (to, form, next) => {
  try {

    let site_name = await getSiteTemplate();
    await setCategory();
    const vuex = localStorage.getItem("vuex");
    const line_id = JSON.parse(vuex).defaultSystem.userLine.userId;

    const inviteCode = to.query.invite === undefined ? "" : to.query.invite;
    const platform = to.query.platform === undefined ? "" : to.query.platform;

    store.commit("defaultSystem/SET_USER", { role: "Customer" });
    if (inviteCode) {
      store.commit("friend/SET_INVITE", inviteCode);
    }

    if (platform) {
      store.commit("friend/SET_PLATFORM", to.query.platform);
    }
    if(line_id){
      await next({ name: "login-bypass" });
      return;
    }
    if (site_name) {
      await next({ name: "login-bypass" });
      return;
    }
  
  } catch (error) {
    console.log("error :>> ", error);
  }
};
